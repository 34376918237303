<template>
<div class="apCodeUpload-page-content winner-page__hero">
   <div class="main-block position-relative">
      <div class="container-xl py-lg-5">
        <div class="row" :class="winnerType == 'heti' ? 'winner-bg--heti':''">
            <div class="col-12 pt-lg-5 custom-padding">
                <!-- mobile -->
                <!-- <div class="d-md-none winner-page__winning">
                    <img v-if="winnerType == 'napi'" src="@/assets/imgs/winnings-napi.png" alt="napi nyememény" class="img-fluid" width="350"/>
                    <img v-if="winnerType == 'heti'" src="@/assets/imgs/winnings-heti.png" width="90%" alt="heti nyeremény" class="img-fluid"/>
                    <img v-if="winnerType == 'fodij'" src="@/assets/imgs/winnings-fodij.png" class="img-fluid ">
                </div> -->

                <div class="winner-bg">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="text-left winner-page--text mt-lg-5 pt-lg-5">
                                <h1 class="text-white text-uppercase fw-900 mb-3">Gratulálunk!</h1>  
                                <p class="text-black fs-22">Érvényes pályázat esetén nyereményed lehet</p>            
                                <p class="text-black fs-22">
                                    <span class="winner-page__hero__winning" v-if="winnerType == 'napi'">1 db Vileda Pet Pro elektrosztatikus kefe</span>
                                    <span class="winner-page__hero__winning " v-if="winnerType == 'heti'">1 db 20 000 Ft értékű PURINA eledel csomag</span>
                                    <span class="winner-page__hero__winning " v-if="winnerType == 'fodij'">1 db Vileda Steam Plus gőztisztító</span>
                                </p>
                            </div>
                        </div>
                        <!-- desctiop -->
                       <div class="col-12 col-md-6 position-relative winner-page__winning">
                            <img v-if="winnerType == 'napi'" src="@/assets/imgs/winner-page-napi.png" class="img-fluid winner-img">
                            <img v-if="winnerType == 'fodij'" src="@/assets/imgs/winner-page-fodij.png" class="img-fluid winner-img">  
                            <img v-if="winnerType == 'heti'" src="@/assets/imgs/winner-page-heti.png" class="img-fluid winner-img">                           
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- <div class="col-12 text-center text-primary order-3">
                <p style="font-size:14px;">A képek csak illusztrációk.</p>
            </div> -->
        </div>   
    </div>    
  </div>
  <!--<Ribbon/>-->
</div>
</template>

<script>
//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    props:['winnerType']
}
</script>